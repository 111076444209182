import { SET_GAMESLIST, SET_GAMESURL, SET_GAMES_DEPOSIT_ALL, SET_GAMES_WITHDRAW, SET_GAMES_CREDIT, STATUS_SUCCESS } from '@/constants'
import { getListGameService, getGameUrlService, setGameDepositCreditAllService, setGameWithdrawCreditService, getGameCreditBalanceService, getGamesHistoryService, getGamesAffiliateService, getHuayHistoryService } from '@/services'

const gameModule = {
  state: {
    list: [],
    url: [],
    gameDiposit: false
  },
  getters: {
    getGames: (state) => state.list,
    getGameUrl: (state) => state.url
  },
  mutations: {
    [SET_GAMESLIST]: (state, payload) => {
      state.list = payload
    },
    [SET_GAMESURL]: (state, payload) => {
      state.url = payload
    },
    [SET_GAMES_DEPOSIT_ALL]: (state, payload) => {
      state.gameDiposit = payload
    }
  },
  actions: {
    setGamesList: ({ commit }, payload) => {
      commit(SET_GAMESLIST, payload)
    },
    getGamesList: async ({ commit }, { type }) => {
      try {
        const resp = await getListGameService(type)
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_GAMESLIST, resp.data)
        }
      } catch (error) {
      }
    },
    getGamesUrl: async ({ commit }, { code, user, providerId }) => {
      try {
        const resp = await getGameUrlService({ code, user, providerId })
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_GAMESURL, resp.data)
        }
      } catch (error) {
      }
    },
    setGameDepositCreditAll: async ({ commit }) => {
      try {
        const resp = await setGameDepositCreditAllService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_GAMES_DEPOSIT_ALL, resp.data)
        }
      } catch (error) {
      }
    },
    setGameWithdrawCreditAll: async ({ commit }, { amount }) => {
      try {
        const resp = await setGameWithdrawCreditService({ amount })
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_GAMES_WITHDRAW, resp.data)
        }
      } catch (error) {
      }
    },
    getGameCreditBalance: async ({ commit }) => {
      try {
        const resp = await getGameCreditBalanceService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_GAMES_CREDIT, resp.data)
        }
      } catch (error) {
      }
    },
    getHuayResultHistory: async ({ commit }, { date }) => {
      try {
        const resp = await getHuayHistoryService(date)
        if (resp.status_code === STATUS_SUCCESS) {
          return resp.data
        }
      } catch (error) {
      }
    },
    getGamesHistory: async ({ commit }, { date }) => {
      try {
        const resp = await getGamesHistoryService(date)
        if (resp.status_code === STATUS_SUCCESS) {
          return resp.data
        }
      } catch (error) {
      }
    },
    getGamesAffiliate: async ({ commit }, { date }) => {
      try {
        const resp = await getGamesAffiliateService(date)
        if (resp.status_code === STATUS_SUCCESS) {
          return resp.data
        }
      } catch (error) {
      }
    }
  }
}
export {
  gameModule
}
