import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const listTodayTranService = async payload => {
  try {
    return await client.get(apiConstant.LISTTODAY, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const listTranHistoryService = async payload => {
  try {
    return await client.get(apiConstant.LIST_TRAN_HISTORY, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const checkTranferService = async payload => {
  try {
    return await client.get(apiConstant.CHECK_TRANSFER, payload)
  } catch (error) {
    throw new Error(error)
  }
}
