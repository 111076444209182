export const SET_NAME = 'setName'
export const SET_BALANCE = 'setBalance'
export const SET_BALANCE_CASINO = 'setBalanceCasino'
export const SET_CREDIT_TOTAL = 'setCreditTotal'
export const SET_VIP = 'setVip'
export const SET_VIP_ACTIVE = 'setVipActive'
export const SET_ISMEMBER = 'setIsMember'
export const SET_ISGAMEPERMISSION = 'setIsGamePermission'
export const SET_ISACTIVE = 'setIsActive'
export const SET_TOKEN = 'setToken'
export const CLEAR_SESSION = 'clearSession'
export const REQUEST_PROFILE = 'requestProfile'
export const SET_LOADING = 'setLoading'
export const SET_PROCESSING = 'setProcessing'
export const SET_LOTTOERYLIST = 'setLotteryList'
export const SET_LOTTOERYLIST_ALL = 'setLotteryListAll'
export const SET_ERRMESSAGE = 'setErrMessage'
export const SET_ACTIVEERROR = 'setActiveError'
export const SET_AFFILIATE_LINK = 'setAffiliateLink'
export const SET_MOBILE = 'setMobile'
export const SET_YEEKEE_HIGHTLIGHT_LIST = 'setYeekeeHighlightList'
export const SET_USER_LEVEL = 'setUserLevel'
export const SET_REGISTERFLAG = 'setRegisterFlag'
export const SET_LOTTO_RESULT = 'setLottoResult'
export const SET_LOTTO_RESULT_ALL = 'setLottoResultAll'
export const SET_LOTTO_BYID = 'setLottoById'
export const SET_THAI_LOTTO = 'setThaiLotto'
export const SET_HIGH_LIGHT = 'setHighLight'
export const SET_NUMBER_BET = 'setNumberBet'
export const SET_LOTTERY_TICKET = 'setLotteryTicket'
export const SET_YEEKI_CATALOG = 'setYeekiCatalog'
export const SET_YEEKI_ROUND = 'setYeekiRound'
export const SET_PROFILE = 'setProfile'
export const SET_MYACCOUNT = 'setMyAccount'
export const SET_BANKLISTREG = 'setBankListReg'
export const SET_NUMBERLIST = 'setNumberList'
export const SET_ROUND_ID = 'setRoundId'
export const SET_YEEKI_INFO = 'setYeekiInfo'
export const SET_RECORD = 'setRecords'
export const SET_RESULT_LOTTO = 'setResultLotto'
export const SET_ACTIVEINFO = 'setActiveInfo'
export const SET_INFOMESSAGE = 'setInfoMessage'
export const SET_MOBILEPHONE = 'setMobilePhone'
export const SET_RESETPROFILE = 'setResetProfile'
export const SET_ADMINBANK = 'setAdminBank'
export const SET_CREDITTURN = 'setCreditTurn'
export const SET_TITLEBET = 'setTitleBet'
export const SET_NEWSLIST = 'setNewsList'
export const SET_CONFIG = 'setConfig'
export const SET_GAMESLIST = 'setGamesList'
export const SET_GAMESURL = 'setGamesUrl'
export const SET_GAMES_DEPOSIT_ALL = 'setGameDepositCreditAll'
export const SET_GAMES_WITHDRAW = 'setGameWithdrawCreditAll'
export const SET_GAMES_CREDIT = 'getGameCreditBalance'
export const SET_GAMES_HISTORY = 'getGameHistory'
export const SET_PROMOTIONSLIST = 'setPromotionsList'
export const SET_AF_KEY = 'setAfKey'
export const SET_AG_KEY = 'setAgKey'
