import {
  STATUS_SUCCESS,
  SET_MOBILE,
  SET_REGISTERFLAG,
  SET_AF_KEY,
  SET_AG_KEY
} from '@/constants'
import { registerVerifyOtp, registerVerifyNumber, registerCreateUser, registerSendOTP, registerSendBank } from '@/services'
import { localStorageService } from '@/utils'
const registerModule = {
  state: {
    mobile: '',
    registerFlag: false,
    afKey: '',
    agKey: '',
    bankAcc: ''
  },
  mutations: {
    [SET_MOBILE]: (state, payload) => {
      state.mobile = payload
    },
    [SET_REGISTERFLAG]: (state, payload) => {
      state.registerFlag = payload
    },
    [SET_AF_KEY]: (state, payload) => {
      state.afKey = payload
    },
    [SET_AG_KEY]: (state, payload) => {
      state.agKey = payload
    }
  },
  getters: {},
  actions: {
    verifyNumber: async ({ commit }, mobile) => {
      try {
        const resp = await registerVerifyNumber(mobile)
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_MOBILE, mobile)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    setAgKey: ({ commit }, payload) => {
      commit(SET_AG_KEY, payload)
      localStorageService.setAgKey(payload)
    },
    clearAgKey: ({ commit }, payload) => {
      commit(SET_AG_KEY, '')
      localStorageService.clearAgKey()
      console.log('clearAgKey')
    },
    setAfKey: ({ commit }, payload) => {
      commit(SET_AF_KEY, payload)
      localStorageService.setAfKey(payload)
    },
    clearAfKey: ({ commit }, payload) => {
      commit(SET_AF_KEY, '')
      localStorageService.clearAfKey()
      console.log('clearAfKey')
    },
    registerCreateUser: async ({ commit, state },
      { username, password, password_confirmation, af, ag, mobile, otp, bankCodeAcc, promotoion_id }) => {
      try {
        const resp = await registerCreateUser(username, password, password_confirmation, (mobile != null ? mobile : state.mobile), af, ag, otp, bankCodeAcc, promotoion_id)
        if (resp.status_code === STATUS_SUCCESS) {
          localStorageService.setToken(null, username, password)
          commit(SET_REGISTERFLAG, true)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    registerSendOTP: async ({ commit, state },
      { mobile }) => {
      try {
        const resp = await registerSendOTP((mobile != null ? mobile : state.mobile))
        if (resp.status_code === STATUS_SUCCESS) {
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    registerSendBank: async ({ commit }, { bankCodeAcc }) => {
      try {
        const resp = await registerSendBank(bankCodeAcc)
        if (resp.status_code === STATUS_SUCCESS) {
          return resp.data
        }
        // if (resp.status_code === STATUS_SUCCESS) {
        //   return true
        // } else {
        //   return false
        // }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    registerVerifyOtp: async ({ commit, state }, { mobile, otp }) => {
      try {
        console.log(mobile, otp)
        const resp = await registerVerifyOtp(mobile, otp)
        if (resp.status_code === STATUS_SUCCESS) {
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    }
  }
}

export { registerModule }
