
export const bankList = [
  {
    id: 1,
    name: 'ธ.กรุงเทพ',
    short: 'bbl',
    assets: '@/assets/banks-logo/th/bbl.svg',
    styles: 'background:#1e4598;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 2,
    name: 'ธ.กสิกรไทย',
    short: 'kbank',
    assets: '@/assets/banks-logo/th/kbank.svg',
    styles: 'background:#138f2d;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 3,
    name: 'ธ.กรุงไทย',
    short: 'ktb',
    assets: '@/assets/banks-logo/th/ktb.svg',
    styles: 'background:#1ba5e1;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 4,
    name: 'ธ.ไทยพาณิชย์',
    short: 'scb',
    assets: '@/assets/banks-logo/th/scb.svg',
    styles: 'background:#4e2e7f;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 5,
    name: 'ธ.กรุงศรีอยุธยา',
    short: 'bay',
    assets: '@/assets/banks-logo/th/bay.svg',
    styles: 'background:#fec43b;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 6,
    name: 'ธ.ซีไอเอ็มบี',
    short: 'cimb',
    assets: '@/assets/banks-logo/th/cimb.svg',
    styles: 'background:#7e2f36;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 7,
    name: 'ธ.อิสลามแห่งประเทศไทย',
    short: 'ibank',
    assets: '@/assets/banks-logo/th/ibank.svg',
    styles: 'background:#184615;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 8,
    name: 'ธ.เกียรตินาคิน',
    short: 'kiatnakin',
    assets: '@/assets/banks-logo/th/kk.svg',
    styles: 'background:#199cc5;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 9,
    name: 'LH',
    short: 'lhbank',
    assets: '@/assets/banks-logo/th/lhb.svg',
    styles: 'background:;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 10,
    name: 'ธ.สแตนดาร์ดชาร์เตอร์ด (ไทย)',
    short: 'standard',
    assets: '@/assets/banks-logo/th/sc.svg',
    styles: 'background:#0f6ea1;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 11,
    name: 'ธ.ธนชาต',
    short: 'thanachart',
    assets: '@/assets/banks-logo/th/tbnk.svg',
    styles: 'background:#fc4f1f;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 12,
    name: 'ธ.ทิสโก้',
    short: 'tisco',
    assets: '@/assets/banks-logo/th/tisco.svg',
    styles: 'background:#12549f;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 13,
    name: 'ธ.ทหารไทย',
    short: 'tmb',
    assets: '@/assets/banks-logo/th/tmb.svg',
    styles: 'background:#1279be;padding:2px;border-radius:2px;width:22px;'
  },
  {
    id: 14,
    name: 'ธ.ยูโอบี',
    short: 'uob',
    assets: '@/assets/banks-logo/th/uob.svg',
    styles: 'background:#0b3979;padding:2px;border-radius:2px;width:22px;'
  }
]
