import { ApiService, localStorageService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const registerCreateUser = (username, password, password_confirmation, mobile, af, ag, otp, bankCodeAcc, promotoion_id) =>
  client.post(((Number(process.env.VUE_APP_BYPASS_OTP) === Number(1)) ? apiConstant.REG_CREATE_BYPASS_OTP : apiConstant.REG_CREATE), {
    username,
    password,
    password_confirmation,
    mobile,
    af,
    ag,
    otp,
    bankCodeAcc,
    promotoion_id
  })
export const registerSendOTP = (mobile) =>
  client.post(apiConstant.REG_OTP, {
    mobile
  })
export const registerSendBank = (bankCodeAcc) =>
  client.post(apiConstant.REG_BANK, {
    bankCodeAcc
  })
export const registerVerifyNumber = mobile =>
  client.post(apiConstant.REG_VERIFY, {
    mobile
  })
export const registerVerifyOtp = async (mobile, otp) => {
  try {
    return await client.post(apiConstant.REG_VERIFY_OTP, {
      mobile,
      otp
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const authenService = async (username, password) => {
  try {
    return await client.post(apiConstant.LOGIN, {
      username,
      password
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getInquiryUserInfomationByID = async () => {
  try {
    return await client.post(apiConstant.USERINFO, JSON.stringify({
      username: localStorageService.getUsername()
    }))
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserProfileInfo = async () => {
  try {
    return await client.get(apiConstant.PROFILE)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateUserProfileService = async (payload) => {
  try {
    return await client.post('/profile/UpdateUserProfile', payload)
  } catch (error) {
    throw new Error(error)
  }
}
