export const IconFlagList = [
  {
    title: 'หวยรัฐบาล',
    class: 'fas fa-crown',
    flag: '',
    background: 'lotto-government'
  },
  {
    title: 'หวยฮานอย',
    flag: 'flag-icon-vn',
    class: 'flag-icon flag-icon-vn',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นเกาหลี',
    flag: 'flag-icon-kr',
    class: 'flag-icon flag-icon-kr',
    background: 'lotto-foreignstock'
  },
  {
    title: 'นิเคอิรอบเช้า',
    flag: 'flag-icon-jp',
    class: 'flag-icon flag-icon-jp',
    background: 'lotto-foreignstock'
  },
  {
    title: 'นิเคอิรอบบ่าย',
    flag: 'flag-icon-jp',
    class: 'flag-icon flag-icon-jp',
    background: 'lotto-foreignstock'
  },
  {
    title: 'จีนรอบเช้า',
    flag: 'flag-icon-cn',
    class: 'flag-icon flag-icon-cn',
    background: 'lotto-foreignstock'
  },
  {
    title: 'จีนรอบบ่าย',
    flag: 'flag-icon-cn',
    class: 'flag-icon flag-icon-cn',
    background: 'lotto-foreignstock'
  },
  {
    title: 'ฮั่งเส็งรอบเช้า',
    flag: 'flag-icon-hk',
    class: 'flag-icon flag-icon-hk',
    background: 'lotto-foreignstock'
  },
  {
    title: 'ฮั่งเส็งรอบบ่าย',
    flag: 'flag-icon-hk',
    class: 'flag-icon flag-icon-hk',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นใต้หวัน',
    flag: 'flag-icon-tw',
    class: 'flag-icon flag-icon-tw',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นสิงคโปร์',
    flag: 'flag-icon-sg',
    class: 'flag-icon flag-icon-sg',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นไทยเย็น',
    flag: 'flag-icon-th',
    class: 'flag-icon flag-icon-th',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นอินเดีย',
    flag: 'flag-icon-in',
    class: 'flag-icon flag-icon-in',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นอียิปต์',
    flag: 'flag-icon-eg',
    class: 'flag-icon flag-icon-eg',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นรัสเซีย',
    flag: 'flag-icon-ru',
    class: 'flag-icon flag-icon-ru',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นเยอรมัน',
    flag: 'flag-icon-de',
    class: 'flag-icon flag-icon-de',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นอังกฤษ',
    flag: 'flag-icon-gb',
    class: 'flag-icon flag-icon-gb',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หุ้นดาวน์โจน',
    flag: 'flag-icon-us',
    class: 'flag-icon flag-icon-us',
    background: 'lotto-foreignstock'
  },
  {
    title: 'หวยลาว',
    flag: 'flag-icon-la',
    class: 'flag-icon flag-icon-la',
    background: 'lotto-la'
  },
  {
    title: 'หวยชุดลาว',
    flag: 'flag-icon-la',
    class: 'flag-icon flag-icon-la',
    background: 'lotto-la'
  },
  {
    title: 'หวยมาเลย์',
    flag: 'flag-icon-my',
    class: 'flag-icon flag-icon-my',
    background: 'lotto-my'
  },
  {
    title: 'หวยลาวสตาร์',
    flag: 'flag-icon-la',
    class: 'flag-icon flag-icon-la',
    background: 'lotto-la'
  },
  {
    title: 'ฮานอย(พิเศษ)',
    flag: 'flag-icon-vn',
    class: 'flag-icon flag-icon-vn',
    background: 'lotto-vn'
  },
  {
    title: 'หวยชุดฮานอย',
    flag: 'flag-icon-vn',
    class: 'flag-icon flag-icon-vn',
    background: 'lotto-vn'
  },
  {
    title: 'ฮานอย(VIP)',
    flag: 'flag-icon-vn',
    class: 'flag-icon flag-icon-vn',
    background: 'lotto-vn-vip2'
  },
  {
    title: 'หวย ธกส.',
    flag: 'flag-icon-th',
    class: 'flag-icon flag-icon-th',
    background: 'lotto-baac'
  },
  {
    title: 'หวย ออมสิน',
    flag: 'flag-icon-th',
    class: 'flag-icon2 flag-icon-th',
    background: 'lotto-gsb'
  }
]
