import { SET_NAME, SET_LOADING } from '@/constants'
const homeModule = {
  state: {
    name: 'name',
    isLoading: false
  },
  getters: {
    getName: (state) => state.name,
    getIsLoading: (state) => state.isLoading
  },
  mutations: {
    [SET_NAME]: (state, name) => {
      state.name = name
    },
    [SET_LOADING]: (state, isLoading) => { state.isLoading = isLoading }
  },
  actions: {
    setLoading: ({ commit }, isLoading) => {
      commit(SET_LOADING, isLoading)
    }
  }
}

export {
  homeModule
}
