export const localStorageService = (() => {
  let _service

  function _getService () {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken (tokenObj, username, password) {
    localStorage.setItem('access_token', tokenObj?.data?.access_token)
    localStorage.setItem('refresh_token', tokenObj?.data?.refresh_token)
    localStorage.setItem('client_id', tokenObj?.data?.client_id)
    localStorage.setItem('game_permission', tokenObj?.data?.game_permission)
    localStorage.setItem('username', username)
    localStorage.setItem('password', password)
  }

  function _setTokenDefault (tokenObj) {
    localStorage.setItem('access_token', tokenObj?.data?.access_token)
    localStorage.setItem('refresh_token', tokenObj?.data?.refresh_token)
    localStorage.setItem('client_id', tokenObj?.data?.client_id)
    localStorage.setItem('game_permission', tokenObj?.data?.game_permission)
  }

  function _setAfKey (afKey) {
    localStorage.setItem('afKey', afKey)
  }

  function _getAfKey () {
    return localStorage.getItem('afKey')
  }

  function _setAgKey (agKey) {
    localStorage.setItem('agKey', agKey)
  }

  function _getAgKey () {
    return localStorage.getItem('agKey')
  }

  function _setUserLevel (level) {
    localStorage.setItem('userLevel', level)
  }

  function _getUserLevel () {
    return localStorage.getItem('userLevel')
  }

  function _isHasToken () {
    return !!(
      window?.localStorage?.getItem('access_token') &&
      window?.localStorage?.getItem('refresh_token')
    )
  }

  function _getAccessToken () {
    return localStorage.getItem('access_token')
  }

  function _getClientId () {
    return localStorage.getItem('client_id')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refresh_token')
  }

  function _getUsername () {
    return localStorage.getItem('username')
  }

  function _getPassword () {
    return localStorage.getItem('password')
  }
  function _clearAfKey () {
    localStorage.removeItem('afKey')
  }
  function _clearAgKey () {
    localStorage.removeItem('agKey')
  }

  function _clearToken () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('client_id')
    localStorage.removeItem('game_permission')
    // localStorage.removeItem('username')
    // localStorage.removeItem('password')
    localStorage.removeItem('userLevel')
  }
  function _getGamePermission () {
    return localStorage.getItem('game_permission')
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getUserLevel: _getUserLevel,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    isHasToken: _isHasToken,
    getUsername: _getUsername,
    getPassword: _getPassword,
    getClientId: _getClientId,
    setUserLevel: _setUserLevel,
    setTokenDefault: _setTokenDefault,
    getGamePermission: _getGamePermission,
    setAfKey: _setAfKey,
    getAfKey: _getAfKey,
    clearAfKey: _clearAfKey,
    setAgKey: _setAgKey,
    getAgKey: _getAgKey,
    clearAgKey: _clearAgKey
  }
})()
