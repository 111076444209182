/* eslint-disable no-prototype-builtins */
export function checkSpecialChar (val) {
  var pattern = /^[A-Za-z0-9_.]+$/
  if (pattern.test(val)) {
    return true
  }
  return false
}

export default function isEmpty (obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}
