import Vue from 'vue'
import Vuex from 'vuex'
import {
  homeModule,
  userModule,
  memberModule,
  authModule,
  lotteryModule,
  affiliateModule,
  registerModule,
  errorModule,
  yeekeeModule,
  highlightModule,
  betModules,
  bankModule,
  gameModule
} from '@/store/modules'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homeModule,
    userModule,
    memberModule,
    authModule,
    affiliateModule,
    betModules,
    lotteryModule,
    registerModule,
    errorModule,
    yeekeeModule,
    highlightModule,
    bankModule,
    gameModule
  }
})
