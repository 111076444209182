import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const submitForgetService = async payload => {
  try {
    return await client.post(apiConstant.SUBMIT_FORGET, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const verifyOTPPinService = async payload => {
  try {
    return await client.post(apiConstant.VERIFY_OTP_PIN, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const submitNewPasswordService = async payload => {
  try {
    return await client.post(apiConstant.SUBMIT_NEW_PASSWORD, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const listUserService = async payload => {
  try {
    return await client.post(apiConstant.LIST_USER_ALL, payload)
  } catch (error) {
    throw new Error(error)
  }
}
