import {
  SET_ISACTIVE,
  STATUS_SUCCESS,
  SET_USER_LEVEL,
  SET_PROFILE
} from '@/constants'
import {
  addBankBookService,
  getInquiryUserInfomationByID,
  getUserProfileInfo
} from '@/services'
import { localStorageService } from '../../utils'
const memberModule = {
  state: {
    isActive: false,
    userLevel: '',
    userProfile: {}
  },
  getters: {
    getIsActive: state => state.isActive,
    getProfile: state => state.userProfile
  },
  mutations: {
    [SET_ISACTIVE]: (state, payload) => {
      state.isActive = payload
    },
    [SET_USER_LEVEL]: (state, payload) => {
      state.userLevel = payload
      localStorageService.setUserLevel(payload)
    },
    [SET_PROFILE]: (state, payload) => {
      state.userProfile = payload
    }
  },
  actions: {
    setIsActive: ({ commit }, payload) => {
      commit(SET_ISACTIVE, payload)
    },
    addNewBookBank: async ({ commit }, payload) => {
      try {
        const resp = await addBankBookService(payload)
        if (resp.status_code === STATUS_SUCCESS) {
          return resp
        } else {
          return resp
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    getUserInfo: async ({ commit }) => {
      try {
        const resp = await getInquiryUserInfomationByID()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_USER_LEVEL, resp?.data?.userLevel)
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    getProfileInfo: async ({ commit }) => {
      try {
        const resp = await getUserProfileInfo()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_PROFILE, resp?.data)
        }
      } catch (error) {}
    }
  }
}

export { memberModule }
