import { localStorageService } from '@/utils'
import {
  SET_TOKEN,
  SET_ISMEMBER,
  SET_ISGAMEPERMISSION,
  CLEAR_SESSION,
  STATUS_SUCCESS
} from '@/constants'
import { authenService } from '@/services'
const authModule = {
  state: {
    isMember: localStorageService.isHasToken(),
    isGamePermission: localStorageService.getGamePermission()
    // isGamePermission: '1'
  },
  mutations: {
    [SET_TOKEN]: (_, payload) => {
      localStorageService.setToken(
        payload.payload,
        payload.username,
        payload.password
      )
    },

    [SET_ISMEMBER]: (state, payload) => {
      state.isMember = payload
    },
    [SET_ISGAMEPERMISSION]: (state, payload) => {
      state.isGamePermission = payload
    },
    [CLEAR_SESSION]: () => {
      localStorageService.clearToken()
    }
  },
  getters: {
    getIsMember: state => state.isMember,
    getIsGamePermission: state => state.isGamePermission
  },
  actions: {
    loginAction: async ({ commit }, { username, password }) => {
      try {
        const resp = await authenService(username, password)
        if (resp.status_code === STATUS_SUCCESS) {
          if ((resp.data.game_permission === undefined || resp.data.game_permission === null)) {
            resp.data.game_permission = 1
          } else {
            resp.data.game_permission = Number(resp.data.game_permission)
          }
          commit(SET_TOKEN, { payload: resp, username, password })
          commit(SET_ISMEMBER, true)
          commit(SET_ISGAMEPERMISSION, resp.data.game_permission)
          // console.log('resp.data.game_permission: ' + resp.data.game_permission)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return err
      }
    },
    setToken: ({ commit }, payload) => {
      commit(SET_TOKEN, payload)
    },
    setIsMember: ({ commit }, payload) => {
      commit(SET_ISMEMBER, payload)
    },
    setIsGamePermission: ({ commit }, payload) => {
      commit(SET_ISGAMEPERMISSION, payload)
    },
    clearLogOut: ({ commit }) => {
      commit(SET_ISMEMBER, false)
      commit(CLEAR_SESSION)
    }
  }
}

export { authModule }
