import {
  avaliableLotto,
  avaliableLottoAll,
  InquiryRoundInfomationByID,
  getResultLotto
} from '@/services'
import {
  SET_LOTTOERYLIST,
  SET_LOTTOERYLIST_ALL,
  STATUS_SUCCESS,
  SET_LOTTO_BYID,
  SET_RESULT_LOTTO
} from '@/constants'
import moment from 'moment'

const lotteryModule = {
  state: {
    lottoryList: [],
    lotTypeList: [],
    listAvaliableLotto: [],
    listAvaliableLottoAll: [],
    inquiryRoundInfomationByID: {
      lot_type_id: 0,
      lottery_type: 0,
      price_limit: null,
      my_price: [],
      limitNumber: [],
      title: '',
      date: {
        round_date: '',
        date_open: '',
        date_close: ''
      },
      status: '',
      advice: '',
      group_name: '',
      round: 0,
      lottoImage: ''
    },
    lottoResult: {
      reward6: 'xxxxxx',
      reward4: 'xxxx',
      reward3_f: [
        'xxx',
        'xxx'
      ],
      reward3_d: ['xxx', 'xxx'],
      reward2_d: 'xx'
    }
  },
  getters: {
    getLotteryList: state => state.listAvaliableLotto,
    getLotTypeList: state => state.lotTypeList,
    getLotteryAllList: state => state.listAvaliableLottoAll,
    getInquiryRoundInfomation: state => state.inquiryRoundInfomationByID,
    getResultLotto: state => state.lottoResult
  },
  mutations: {
    [SET_RESULT_LOTTO]: (state, payload) => {
      if (payload) {
        state.lottoResult = payload
        // {
        //   reward6: payload.reward6,
        //   reward4: payload.reward4,
        //   reward3_f: payload.reward3_f,
        //   reward3_d: payload.reward3_d,
        //   reward2_d: payload.reward2_d
        // }
      } else {
        state.lottoResult = {
          reward6: 'xxxxxx',
          reward4: 'xxxx',
          reward3_f: [
            'xxx',
            'xxx'
          ],
          reward3_d: ['xxx', 'xxx'],
          reward2_d: 'xx'
        }
      }
    },
    [SET_LOTTOERYLIST]: (state, data) =>
      (state.listSubCatYeekiAviliable = data),

    [SET_LOTTOERYLIST]: (state, payload) => {
      state.listAvaliableLotto = payload
    },
    [SET_LOTTOERYLIST_ALL]: (state, payload) => {
      state.lotTypeList = payload.lot_type
      state.listAvaliableLottoAll = payload.listRoundLotto
    },
    [SET_LOTTO_BYID]: (state, payload) => {
      state.inquiryRoundInfomationByID = {
        lot_type_id: payload.lot_type_id,
        lottery_type: payload.lottery_type,
        price_limit: payload.price_limit,
        my_price: payload.my_price,
        limitNumber: payload.limitNumber,
        title: payload.title,
        date: {
          round_date: moment(payload.date.round_date),
          date_open: moment(payload.date.date_open),
          date_close: moment(payload.date.date_close)
        },
        status: payload.status,
        advice: payload.advice,
        group_name: payload.group_name,
        round: payload.round,
        lottoImage: payload.lottoImage
      }
    }
  },
  actions: {
    getLottoInter: async ({ commit }) => {
      try {
        const payload = await avaliableLotto()
        if (payload.status_code === STATUS_SUCCESS) {
          // commit(SET_LOTTOERYLIST, payload ? .data ? .listAvaliableLotto)
          commit(SET_LOTTOERYLIST, payload.data)
        }
      } catch (error) {}
    },
    getLottoAll: async ({ commit }) => {
      try {
        const payload = await avaliableLottoAll()
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_LOTTOERYLIST_ALL, payload.data)
        }
      } catch (error) {}
    },
    getInquiryRoundInfomationByID: async ({ commit }, id) => {
      try {
        const payload = await InquiryRoundInfomationByID(id)
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_LOTTO_BYID, payload.data)
        }
      } catch (error) {}
    },
    clearResult: ({
      commit
    }) => {
      try {
        const reset = {
          reward6: 'xxxxxx',
          reward4: 'xxxx',
          reward3_f: [
            'xxx',
            'xxx'
          ],
          reward3_d: ['xxx', 'xxx'],
          reward2_d: 'xx'
        }
        commit(SET_RESULT_LOTTO, reset)
      } catch (error) {}
    },
    fetchResultLotto: async ({
      commit
    }, roundId) => {
      try {
        const payload = await getResultLotto(roundId)
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_RESULT_LOTTO, payload.data)
        } else {
          const reset = {
            reward6: 'xxxxxx',
            reward4: 'xxxx',
            reward3_f: [
              'xxx',
              'xxx'
            ],
            reward3_d: ['xxx', 'xxx'],
            reward2_d: 'xx'
          }
          commit(SET_RESULT_LOTTO, reset)
        }
      } catch (error) {}
    }
  }
}

export {
  lotteryModule
}
