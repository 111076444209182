import {
  ApiService,
  localStorageService
} from '@/utils'
import {
  apiConstant
} from '@/constants'

const client = new ApiService({})

export const inquiryYeekiByType = async yeekiType => {
  try {
    return await client.post('/inquiryYeekiByType', yeekiType)
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryYeekiCategory = async () => {
  try {
    return await client.get('/yeekee/InquiryYeekiCategory')
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryYeekiRoundAvaliable = async id => {
  try {
    return await client.post(
      '/yeekee/inquiryYeekiRoundAvaliable',
      JSON.stringify({
        subCatID: id
      })
    )
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryHistoryLotto = async dateTime => {
  try {
    return await client.post('/inquiryHistoryLotto', dateTime)
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryStockLotto = () =>
  client.get('/inquiryStockLotto', {
    params: {}
  })

export const inquiryYeekiHilight = () =>
  client.get('/inquiryYeekiHilight', {
    params: {}
  })

export const inquiryThaiLotto = () =>
  client.get('/inquiryThaiLotto', {
    params: {}
  })

export const InquiryRoundInfomationByID = async id => {
  try {
    return await client.post(
      '/lotto/InquiryRoundInfomationByID',
      JSON.stringify({
        username: localStorageService.getUsername(),
        roundID: id
      })
    )
  } catch (error) {
    throw new Error(error)
  }
}

export const InquiryYeekiRoundInfomationByID = async id => {
  try {
    return await client.post(
      '/lotto/InquiryYeekiRoundInfomationByID',
      JSON.stringify({
        username: localStorageService.getUsername(),
        roundID: id
      })
    )
  } catch (error) {
    throw new Error(error)
  }
}

export const getResultLotto = async (roundId) => {
  try {
    return await client.post('/lotto/getResultLottoByRoundID', JSON.stringify({
      lottoRoundID: roundId
    }))
  } catch (error) {
  }
}

export const avaliableLottoAll = async () => {
  try {
    return await client.get(apiConstant.LOTTO_LIST_ALL)
  } catch (error) {
    throw new Error(error)
  }
}

export const avaliableLotto = async () => {
  try {
    return await client.get(apiConstant.LOTTO_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const submitNumberLottoServices = async (payload) => {
  try {
    return await client.post(apiConstant.SUBMIT, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLotteryTicketServices = async (payload) => {
  try {
    return await client.post(apiConstant.LOTTORY_TICKET, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAvailableLottoSet = async () => {
  try {
    return await client.get('/lotto/listAvaliableLottoSet')
  } catch (error) {
    throw new Error(error)
  }
}

export const getAvailableLottoTicket = async () => {
  try {
    return await client.get('/lotto/listAvailableLottoTicket')
  } catch (error) {
    throw new Error(error)
  }
}

export const getInquirySetLottoRoundInfomationByID = async (id) => {
  try {
    return await client.post('/lotto/InquirySetLottoRoundInfomationByID', {
      username: localStorageService.getUsername(),
      roundID: id
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getNumberSet = async () => {
  try {
    return await client.get('/set/listSetNumber')
  } catch (error) {
    throw new Error(error)
  }
}

export const setNumberSet = async (payload) => {
  try {
    return await client.post('/set/submitSetNumberTemplate', payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteNumberSet = async (id) => {
  try {
    return await client.post('/set/deleteSetNumber', {
      setID: id
    })
  } catch (error) {
    throw new Error(error)
  }
}

// roundID
export const listPushNumberYeekee = async (payload) => {
  try {
    return await client.post('/yeekee/listPushNumberYeekee', payload)
  } catch (error) {
    throw new Error(error)
  }
}

// roundID
// pushNumber
export const pushNumberYeekee = async (payload) => {
  try {
    return await client.post('/yeekee/pushNumberYeekee', payload)
  } catch (error) {
    throw new Error(error)
  }
}

// lottoRoundID
export const getResultYeekeeRoundID = async (payload) => {
  try {
    return await client.post('/lotto/getResultYeekeeRoundID', payload)
  } catch (error) {
    throw new Error(error)
  }
}

// getRuleByID
export const getRuleByID = async id => {
  try {
    return await client.post('/lotto/getRuleByID', JSON.stringify({
      username: localStorageService.getUsername(),
      lottoCatID: id
    }))
  } catch (error) {
    throw new Error(error)
  }
}

export const getListPromotionsService = async id => {
  try {
    return await client.post(apiConstant.PROMOTIONS_LIST, JSON.stringify({
      aid: id
    }))
  } catch (error) {
    throw new Error(error)
  }
}
