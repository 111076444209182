import { getMyAccountService, getBankListService, getBankListServiceReg } from '@/services'
import { SET_MYACCOUNT, STATUS_SUCCESS, SET_ADMINBANK, SET_CREDITTURN, SET_BANKLISTREG } from '@/constants'
import { isEmpty } from '@/utils'
export const bankModule = {
  state: {
    hasBank: null,
    myBankInfo: {},
    adminBank: {},
    creditTurn: '',
    bankListReg: []
  },
  mutations: {
    [SET_MYACCOUNT]: (state, payload) => {
      if (!isEmpty(payload.listBankAccount || payload.listBankAccount.length < 1)) {
        state.hasBank = true
      } else {
        state.hasBank = false
      }
      state.myBankInfo = payload
    },
    [SET_ADMINBANK]: (state, payload) => {
      state.adminBank = payload
    },
    [SET_CREDITTURN]: (state, payload) => {
      state.creditTurn = payload
    },
    [SET_BANKLISTREG]: (state, payload) => {
      state.bankListReg = payload
    }
  },
  actions: {
    getMyAccountBanks: async ({ commit }) => {
      try {
        const resp = await getMyAccountService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_MYACCOUNT, resp.data)
        }
      } catch (error) {
        console.error(`im err : ${error}`)
      }
    },
    inquiryListBank: async () => {
      try {
        const resp = await getBankListService()
        if (resp.status_code === STATUS_SUCCESS) {
          return resp.data.listBankAccount
        }
      } catch (error) {
        console.error(`im err : ${error}`)
      }
    },
    inquiryListBankReg: async ({ commit }) => {
      try {
        const resp = await getBankListServiceReg()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_BANKLISTREG, resp.data)
        }
      } catch (error) {
        console.error(`im err : ${error}`)
      }
    },
    setAdminBank: ({ commit }, payload) => {
      commit(SET_ADMINBANK, payload)
    },
    setCreditTurn: ({ commit }, payload) => {
      commit(SET_CREDITTURN, payload)
    }
  }
}
