import { SET_NUMBER_BET, SET_NUMBERLIST, SET_ROUND_ID, SET_RECORD, SET_TITLEBET, SET_LOTTERY_TICKET } from '@/constants'
import { submitNumberLottoServices, getLotteryTicketServices } from '@/services'

export const betModules = {
  state: {
    numberBet: {
      roundID: null,
      type: [],
      number: [],
      price: [],
      pay: []
    },
    roundID: null,
    numberList: [],
    recordBets: [],
    titleBet: '',
    lotteryTicketList: []
  },
  getters: {
    getNumberBet: state => state.numberBet,
    getNumberList: state => state.numberList,
    getRoundId: state => state.roundID,
    getRecords: state => state.recordBets
  },
  mutations: {
    [SET_NUMBER_BET]: (state, payload) => {
      state.numberBet = payload
    },
    [SET_NUMBERLIST]: (state, payload) => {
      state.numberList = payload
    },
    [SET_ROUND_ID]: (state, roundId) => {
      state.roundID = roundId
    },
    [SET_RECORD]: (state, payload) => {
      state.recordBets = payload
    },
    [SET_TITLEBET]: (state, payload) => {
      state.titleBet = payload
    },
    [SET_LOTTERY_TICKET]: (state, payload) => {
      state.lotteryTicketList = payload
    }
  },
  actions: {
    setNumberSet: ({ commit }, payload) => {
      commit(SET_NUMBER_BET, payload)
    },
    setNumberList: ({ commit }, payload) => {
      commit(SET_NUMBERLIST, payload)
    },
    setRoundId: ({ commit }, roundId) => {
      commit(SET_ROUND_ID, roundId)
    },
    getLotteryTicket: async ({ commit }, data) => {
      try {
        const payload = await getLotteryTicketServices(data)
        if (payload.data) {
          return payload.data
        } else {
          return []
        }
      } catch (error) {
        return error
      }
    },
    submitNumberLotto: async ({ commit }, data) => {
      try {
        const payload = await submitNumberLottoServices(data)
        if (payload.data.warning) {
          payload.data.book_records.map(e => {
            if (payload.data.warning.find(d => ((Number(d.type) === Number(e.typeN)) && (Number(d.num) === Number(e.number)) && (Number(d.oldPay) !== Number(e.pay * e.price))))) {
              e.isChange = true
            } else {
              e.isChange = false
            }
          })
        } else {
          payload.data.book_records.map(e => {
            e.isChange = false
          })
        }
        commit(SET_RECORD, payload.data.book_records)
        return payload
      } catch (error) {
        return error
      }
    },
    setTitleBet: ({ commit }, payload) => {
      commit(SET_TITLEBET, payload)
    }
  }
}
