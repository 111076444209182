import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getListGameService = async type => {
  try {
    return await client.post(apiConstant.LIST_GAME, {
      type: type
    }, { timeout: 30 })
  } catch (error) {
    throw new Error(error)
  }
}
export const getGameUrlService = async parameter => {
  try {
    return await client.post(apiConstant.URL_GAME, JSON.stringify({
      user: parameter.user,
      code: parameter.code,
      providerId: parameter.providerId
    }), { timeout: 10 })
  } catch (error) {
    throw new Error(error)
  }
}
export const setGameDepositCreditAllService = async () => {
  try {
    return await client.post(apiConstant.DEPOSIT_CREDIT_GAME, { timeout: 30 })
  } catch (error) {
    throw new Error(error)
  }
}
export const setGameWithdrawCreditService = async (parameter) => {
  try {
    return await client.post(apiConstant.WITHDRW_CREDIT_GAME, JSON.stringify({
      amount: parameter.amount
    }))
  } catch (error) {
    throw new Error(error)
  }
}
export const getGameCreditBalanceService = async () => {
  try {
    return await client.post(apiConstant.CREDIT_GAME)
  } catch (error) {
    throw new Error(error)
  }
}
export const getHuayHistoryService = async date => {
  try {
    return await client.post(apiConstant.HUAY_HISTORY, {
      limit: date.limit,
      lotType: date.lotType
    })
  } catch (error) {
    throw new Error(error)
  }
}
export const getGamesHistoryService = async date => {
  try {
    return await client.post(apiConstant.GAME_HISTORY, {
      limit: date.limit,
      lotType: date.lotType
    })
  } catch (error) {
    throw new Error(error)
  }
}
export const getGamesAffiliateService = async date => {
  try {
    return await client.post(apiConstant.GAME_BALANCE_CASINO_AFFILIATE, {
      dateFrom: date.from,
      dateTo: date.to
    })
  } catch (error) {
    throw new Error(error)
  }
}
