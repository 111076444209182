import { ApiService, localStorageService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const addBankBookService = async payload => {
  try {
    return await client.post(apiConstant.ADD_ACCOUNT, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const getBankListService = async () => {
  try {
    return await client.get(apiConstant.LISTBANK)
  } catch (error) {
    throw new Error(error)
  }
}

export const getBankListServiceReg = async () => {
  try {
    return await client.get(apiConstant.LISTBANKREG)
  } catch (error) {
    throw new Error(error)
  }
}

export const getListNewsService = async () => {
  try {
    return await client.get(apiConstant.NEWS_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const reqConfig = async (id, afKey, agKey) => {
  try {
    return await client.post(apiConstant.CONFIG, JSON.stringify({
      id: id,
      afKey: afKey,
      agKey: agKey
    }))
  } catch (error) {
    throw new Error(error)
  }
}

export const getBalanceService = async () => {
  try {
    return await client.post(apiConstant.GET_BALANCE, {
      username: localStorageService.getUsername()
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getMyAccountService = async () => {
  try {
    return await client.get(apiConstant.MYACCOUNT)
  } catch (error) {
    throw new Error(error)
  }
}

export const submitDepositServiceP2p = async (amount, acountID, promotoion_id) => {
  try {
    return await client.post(apiConstant.SUBMIT_DEPOSIT_P2P, { amount, acountID, promotoion_id })
  } catch (error) {
    throw new Error(error)
  }
}
export const submitDepositService = async (amount, acountID, promotoion_id) => {
  try {
    return await client.post(apiConstant.SUBMIT_DEPOSIT, { amount, acountID, promotoion_id })
  } catch (error) {
    throw new Error(error)
  }
}
export const veriryDepositService = async (transaction_code, img_type, image) => {
  try {
    return await client.post(apiConstant.VERIFY_DEPOSIT, { transaction_code, img_type, image })
  } catch (error) {
    throw new Error(error)
  }
}

export const getStatusDepService = async () => {
  try {
    return await client.get(apiConstant.STATUS_DEPOSIT)
  } catch (error) {
    throw new Error(error)
  }
}
export const depositCachViewService = async () => {
  try {
    return await client.get(apiConstant.DEPOSIT_CASH)
  } catch (error) {
    throw new Error(error)
  }
}

export const CancleDepositTransferService = async () => {
  try {
    return await client.post(apiConstant.DEPOSIT_CANCLE)
  } catch (error) {
    throw new Error(error)
  }
}

export const confirmDepositService = async (amount, acountID, time_trasfer, note) => {
  try {
    return await client.post(apiConstant.CONFIRM_DEPOSIT, { amount, acountID, time_trasfer, note })
  } catch (error) {
    throw new Error(error)
  }
}

export const makeTransferService = async (amount, note) => {
  try {
    return await client.post(apiConstant.MAKE_TRANSFER, { amount, note })
  } catch (error) {
    throw new Error(error)
  }
}

export const checkCreditTurn = async () => {
  try {
    return await client.get(apiConstant.CHECK_CREDITTURN)
  } catch (error) {
    throw new Error(error)
  }
}

export const listBankMasterService = async () => {
  try {
    return await client.post(apiConstant.LIST_BANK_MASTER)
  } catch (error) {
    throw new Error(error)
  }
}
