import {
  ApiService,
  localStorageService
} from '@/utils'
import {
  apiConstant
} from '@/constants'

const client = new ApiService({})

export const affiliateOverallService = async () => {
  try {
    return await client.get(apiConstant.AFFILIATE_OVERALL)
  } catch (error) {
    throw new Error(error)
  }
}

export const findAllFriendAffiliateService = async () => {
  try {
    return await client.get('/affiliate/findAllFriendAffiliate')
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryIncomeAffiliateService = async () => {
  try {
    return await client.post('/affiliate/inquiryIncomeAffiliate', {
      username: localStorageService.getUsername()
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const withdrawalAffiliateService = async (amount) => {
  try {
    return await client.post('/affiliate/withdrawalAffiliate', {
      amountWithdraw: amount,
      username: localStorageService.getUsername()
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryBalanceAffiliateService = async (mountYear) => {
  try {
    return await client.post('/affiliate/inquiryBalanceAffiliate', {
      mountYear: mountYear,
      username: localStorageService.getUsername()
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const inquiryStagementAffiliateService = async () => {
  try {
    return await client.post('/affiliate/inquiryStagementAffiliate', {
      username: localStorageService.getUsername()
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const listAllBetHistoryService = async () => {
  try {
    return await client.post('/history/ListAllBetHistory')
  } catch (error) {
    throw new Error(error)
  }
}

export const listAllBetHistoryServiceFull = async () => {
  try {
    return await client.post('/history/ListAllBetHistoryFull')
  } catch (error) {
    throw new Error(error)
  }
}

export const listAllBetHistoryServiceById = async (id) => {
  try {
    return await client.post('/history/ListAllBetHistoryById', {
      ticket_id: id
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const returnBetServiceById = async (id) => {
  try {
    return await client.post('/lotto/returnBetServiceById', {
      ticket_id: id
    })
  } catch (error) {
    throw new Error(error)
  }
}
