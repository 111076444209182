
export const dummyList = {
  // getYeekiCatalogList: [
  //   {
  //     subCatID: '2',
  //     subCatName: 'จับยี่กี',
  //     subCatDetail: 'เปิดแทง 88 รอบ',
  //     styleName: 'lotto-yeekee',
  //     styleBadgeName: 'badge-dark'
  //   }, {
  //     subCatID: '3',
  //     subCatName: 'จับยี่กี VIP',
  //     subCatDetail: 'เปิดแทง 88 รอบ',
  //     styleName: 'lotto-yeekee',
  //     styleBadgeName: 'badge-dark'
  //   }
  // ],
  // VUE_APP_YEEKEE_EXPRESS_EN
  getYeekiCatalogList: [
    {
      subCatID: '2',
      subCatName: 'ยี่กี',
      subCatDetail: 'เปิดแทง 88 รอบ',
      styleName: 'lotto-yeekee',
      styleBadgeName: 'badge-black',
      img: 'yeekee2.webp',
      bgClass: 'bg_huay',
      isOpen: '1',
      lottoCatID: '2',
      lottoImage: 'circle/yeekee1.webp',
      lottoType: 2,
      titleLottoCatagory: 'ยี่กี 15 นาที',
      isYeekee: true
    }, {
      subCatID: '3',
      subCatName: 'ยี่กี VIP',
      subCatDetail: 'เปิดแทง 88 รอบ',
      styleName: 'lotto-yeekee',
      styleBadgeName: 'badge-black',
      img: 'yeekee2.webp',
      bgClass: 'bg_huay',
      isOpen: '1',
      lottoCatID: '3',
      lottoImage: 'circle/yeekee1.webp',
      lottoType: 3,
      titleLottoCatagory: 'ยี่กี 15 นาที VIP',
      isYeekee: true
    },
    // TG1
    {
      subCatID: '43',
      subCatName: 'ยี่กี 5 นาที',
      subCatDetail: 'เปิดแทง 264 รอบ',
      styleName: 'lotto-yeekee-express',
      styleBadgeName: 'badge-black',
      img: 'yeekee1.webp',
      bgClass: 'bg_huay2',
      isOpen: '1',
      lottoCatID: '43',
      lottoImage: 'circle/yeekee1.webp',
      lottoType: 43,
      titleLottoCatagory: 'ยี่กี 5 นาที',
      isYeekee: true
    }
    // PYN
    // {
    //   subCatID: '42',
    //   subCatName: 'ยี่กี 5 นาที',
    //   subCatDetail: 'เปิดแทง 264 รอบ',
    //   styleName: 'lotto-yeekee-express',
    //   styleBadgeName: 'badge-black',
    //   img: 'yeekee1.webp',
    //   bgClass: 'bg_huay2',
    //   isOpen: '1',
    //   lottoCatID: '42',
    //   lottoImage: 'circle/yeekee1.webp',
    //   lottoType: 42,
    //   titleLottoCatagory: 'ยี่กี 5 นาที',
    //   isYeekee: true
    // }
  ],
  getLotteryList: [
    {
      groupName: 'หวย',
      list: [
        // {
        //   lottoId: '64267',
        //   lottoType: '40',
        //   lottoImage: 'circle/gsb.svg',
        //   lottoName: '28/08/2021',
        //   lottoCatID: '0',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-27 12:30:00',
        //   lottoMessage: '28/08/2021',
        //   titleLottoCatagory: 'หวย ออมสิน'
        // },
        // {
        //   lottoId: '64449',
        //   lottoType: '39',
        //   lottoImage: 'circle/baac.svg',
        //   lottoName: '29 สิงหาคม 2564',
        //   lottoCatID: '0',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-29 23:00:00',
        //   lottoMessage: '29 สิงหาคม 2564',
        //   titleLottoCatagory: 'หวย ธกส.'
        // },
        // {
        //   lottoId: '63360',
        //   lottoType: '1',
        //   lottoImage: 'govement/huayrat_small.png',
        //   lottoName: '23.8.64',
        //   lottoCatID: '0',
        //   isOpen: '3',
        //   lottoCloseTime: '2021-08-24 14:51:00',
        //   lottoMessage: '23.8.64',
        //   titleLottoCatagory: 'หวยรัฐบาล'
        // }
      ]
    },
    {
      groupName: 'หวยหุ้น',
      list: [
        // {
        //   lottoId: '63380',
        //   lottoType: '32',
        //   lottoImage: 'circle/laos.svg',
        //   lottoName: '23/08/2564',
        //   lottoCatID: '2',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-23 20:00:00',
        //   lottoMessage: '23/08/2564',
        //   titleLottoCatagory: 'หวยลาว'
        // },
        // {
        //   lottoId: '63371',
        //   lottoType: '19',
        //   lottoImage: 'circle/china.svg',
        //   lottoName: '23/08/2564',
        //   lottoCatID: '2',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-24 13:40:00',
        //   lottoMessage: '23/08/2564',
        //   titleLottoCatagory: 'จีนรอบบ่าย'
        // },
        // {
        //   lottoId: '63372',
        //   lottoType: '20',
        //   lottoImage: 'circle/hong-kong.svg',
        //   lottoName: '23/08/2564',
        //   lottoCatID: '2',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-24 10:55:00',
        //   lottoMessage: '23/08/2564',
        //   titleLottoCatagory: 'ฮั่งเส็งรอบเช้า'
        // },
        // {
        //   lottoId: '63374',
        //   lottoType: '22',
        //   lottoImage: 'circle/taiwan.svg',
        //   lottoName: '23/08/2564',
        //   lottoCatID: '2',
        //   isOpen: '2',
        //   lottoCloseTime: '2021-08-24 12:10:00',
        //   lottoMessage: '23/08/2564',
        //   titleLottoCatagory: 'หุ้นใต้หวัน'
        // }
      ]
    }
  ]
}
