
import { SET_AFFILIATE_LINK, STATUS_SUCCESS } from '@/constants'
import { affiliateOverallService } from '@/services'

export const affiliateModule = {
  state: {
    url: '',
    currentIncome: '',
    totalIncome: '',
    totalClick: '',
    downline: 0,
    totalBet: 0
  },
  mutations: {
    [SET_AFFILIATE_LINK]: (state, payload) => {
      state.url = payload.linkAffiliate
      state.currentIncome = payload.currentIncome
      state.totalIncome = payload.totalIncome
      state.totalClick = payload.totalClick
      state.downline = payload.downline
      state.totalBet = payload.totalBet
    }
  },
  getters: {
    getAffiliateLink: state => state.url,
    getCurrentIncome: state => state.currentIncome,
    getTotalClick: state => state.totalClick,
    getTotalIncome: state => state.totalIncome,
    getDownline: state => state.downline,
    getTotalBet: state => state.totalBet
  },
  actions: {
    getAffiliateInfo: async ({ commit }) => {
      try {
        const resp = await affiliateOverallService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_AFFILIATE_LINK, resp.data)
        }
      } catch (error) {
        console.error(`im err : ${error}`)
      }
    }
  }
}
