import {
  inquiryYeekiByType,
  inquiryStockLotto,
  inquiryThaiLotto,
  inquiryYeekiHilight,
  inquiryHistoryLotto
} from '@/services'
import {
  SET_THAI_LOTTO,
  SET_HIGH_LIGHT,
  SET_YEEKEE_HIGHTLIGHT_LIST,
  SET_LOTTO_RESULT,
  SET_LOTTO_RESULT_ALL,
  STATUS_SUCCESS
} from '@/constants'

export const highlightModule = {
  state: {
    lottoHighLightList: [],
    lottoThaiList: null,
    lottoHightLightList: [],
    yeekeeResultList: [],
    yeekeeVIPResultList: [],
    lottoResultAll: [[], [[], [], []], [], []]
  },
  getters: {
    getLottoHighLightList: state => state.lottoHighLightList,
    getThaiLottoList: state => state.lottoThaiList,
    getLottoResultList: state => state.lottoHightLightList,
    getYeeKeeResultList: state => state.yeekeeResultList,
    getYeekiVips: state => state.yeekeeVIPResultList,
    getLottoResultAllList: state => state.lottoResultAll
  },
  mutations: {
    [SET_HIGH_LIGHT]: (state, payload) => {
      state.lottoHighLightList = payload
    },
    [SET_THAI_LOTTO]: (state, payload) => {
      state.lottoThaiList = payload
    },
    [SET_YEEKEE_HIGHTLIGHT_LIST]: (state, payload) => {
      state.yeekeeResultList = payload
    },
    SET_YEEKI_VIP: (state, payload) => {
      state.yeekeeVIPResultList = payload
    },
    [SET_LOTTO_RESULT]: (state, payload) => {
      state.lottoHightLightList = payload
    },
    [SET_LOTTO_RESULT_ALL]: (state, payload) => {
      state.lottoResultAll = payload
    }
  },
  actions: {
    getLottoResultAll: async ({ commit }, time) => {
      try {
        const payload = await inquiryHistoryLotto(
          JSON.stringify({
            dateTime: time
          })
        )
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_LOTTO_RESULT_ALL, payload?.data)
        }
      } catch (error) {}
    },
    getLottoHighlight: async ({
      commit
    }) => {
      try {
        const payload = await inquiryStockLotto()
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_LOTTO_RESULT, payload?.data)
        }
      } catch (error) {}
    },
    getYeeKeeHighLightResult: async ({
      commit
    }) => {
      try {
        const payload = await inquiryYeekiHilight()
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_HIGH_LIGHT, payload?.data)
        }
      } catch (error) {}
    },
    getYeekeeByType: async ({
      commit
    }) => {
      try {
        const payload = await inquiryYeekiByType(
          JSON.stringify({
            yeekiType: 'YK'
          })
        )
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_YEEKEE_HIGHTLIGHT_LIST, payload?.data)
        }
      } catch (error) {}
    },
    getYeekeeByTypeVIP: async ({
      commit
    }) => {
      try {
        const payload = await inquiryYeekiByType(
          JSON.stringify({
            yeekiType: 'YKVIP'
          })
        )
        if (payload.status_code === STATUS_SUCCESS) {
          commit('SET_YEEKI_VIP', payload?.data)
        }
      } catch (error) {}
    },
    getThaiLotto: async ({
      commit
    }) => {
      try {
        const payload = await inquiryThaiLotto()
        if (payload.status_code === STATUS_SUCCESS) {
          commit(SET_THAI_LOTTO, payload?.data)
        }
      } catch (error) {}
    }
  }
}
