/* eslint-disable new-cap */
import Vue from 'vue'
import App from './App.vue'
import router from '@/routes'
import store from '@/store'
import VueI18n from 'vue-i18n'
import VueMobileDetection from 'vue-mobile-detection'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'
import lodash from 'lodash'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import VCalendar from 'v-calendar'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import { BootstrapVue } from 'bootstrap-vue'
/* bootstrap */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

import {
  languages,
  defaultLocale
} from '@/i18n'

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueMobileDetection)
Vue.use(VueI18n)
Vue.use(VTooltip)
Vue.use(VueClipboard)
Vue.use(VCalendar)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)

Vue.prototype.$moment = moment
Vue.prototype.$_ = lodash

const messages = Object.assign(languages)

const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  messages // set locale messages
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
