export function checkNumber (val) {
  var pattern = /^[0-9]*$/
  if (pattern.test(val)) {
    return true
  }
  return false
}

export function checkNumeric (val) {
  var pattern = /^[0-9]*([.][0-9]*)?$/
  if (pattern.test(val)) {
    return true
  }
  return false
}
