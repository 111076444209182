// import moment from 'moment'
export function unavailableTime () {
  // const current = moment()
  // const date1 = new Date(`${current.format('YYYY-MM-DD')} 22:50:00`)
  // const date2 = new Date(`${current.format('YYYY-MM-DD')} 00:10:00`)
  // const beforeTime = moment(date1)
  // const afterTime = moment(date2)
  // const isBefore = current.isSameOrBefore(afterTime)
  // const isAfter = current.isSameOrAfter(beforeTime)
  // if (isBefore) {
  //   return true
  // } else if (isAfter) {
  //   return true
  // } else {
  return false
  // }
}
