export const STATUS_SUCCESS = 'SUCCESS'

export const apiConstant = {
  REFRESH_TOKEN: '/refreshToken',
  AFFILIATE_OVERALL: '/affiliate/inquiryOverallAffiliate',
  REG_VERIFY: '/registerVerifyNumber',
  REG_VERIFY_OTP: '/registerVerifyOtp',
  // LOGIN: '/login',
  LOGIN: '/login',
  REG_OTP: '/registerSendOTP',
  REG_BANK: '/registerSendBank',
  // VUE_APP_EXT_NAME
  REG_CREATE: '/registerCreateUser',
  REG_CREATE_BYPASS_OTP: '/registerCreateUserWithoutOtp',
  ADD_ACCOUNT: '/addAccountNumber',
  GET_BALANCE: '/user/inquiryUserBalance',
  GET_BALANCE_CASINO: '/game/credit/balance',
  LOTTO_LIST: '/lotto/listAvaliableLotto',
  LOTTO_LIST_ALL: '/lotto/listRoundLotto',
  LOTTORY_TICKET: '/lotto/getLotteryTicket',
  USERINFO: '/user/inquiryUserInfomationByID',
  LISTBANK: '/bank/inquiryListBank',
  LISTBANKREG: '/bank/finds',
  PROFILE: '/GetUserStatus',
  NEWS_LIST: '/newsList',
  CONFIG: '/config',
  PROMOTIONS_LIST: '/promotionsList',
  MYACCOUNT: '/withdraw/listMyAccount',
  SUBMIT: '/lotto/submitNumberLotto',
  SUBMIT_DEPOSIT: '/deposit/submitAmountTransfer',
  SUBMIT_DEPOSIT_P2P: '/bank/getBankAccount',
  VERIFY_DEPOSIT: '/bank/verifyDeposit',
  DEPOSIT_CASH: '/bank/DepositCachView',
  DEPOSIT_CANCLE: '/bank/cancelDeposit',
  STATUS_DEPOSIT: '/bank/checkStatusStagementDeposit',
  CONFIRM_DEPOSIT: '/deposit/ConfirmAmountTransfer',
  MAKE_TRANSFER: '/bank/createWithdraw',
  CHECK_CREDITTURN: '/withdraw/checkCreditTurn',
  LISTTODAY: '/history/listTodayTransaction',
  // CHECK_TRANSFER: '/deposit/checkBalanceTransfer',
  CHECK_TRANSFER: '/stagement/getStagementToday',
  LIST_TRAN_HISTORY: '/history/listTransactionHistory',
  LIST_BANK_MASTER: '/profile/listBankMaster',
  SUBMIT_FORGET: '/forgot/submitOTPForgotPassword',
  VERIFY_OTP_PIN: '/forgot/verifyOTPPin',
  LIST_USER_ALL: '/forgot/ListUserAllowSubmitNewPassword',
  SUBMIT_NEW_PASSWORD: '/forgot/submitNewPassword',
  LIST_GAME: '/games',
  URL_GAME: '/games/url',
  DEPOSIT_CREDIT_GAME: '/game/credit/deposit',
  WITHDRW_CREDIT_GAME: '/game/credit/withdraw',
  GAME_HISTORY: '/games/userHistory',
  HUAY_HISTORY: '/getHuayHistory',
  GAME_BALANCE_CASINO_AFFILIATE: '/affiliate/inquiryAffiliateRevenue'
}
