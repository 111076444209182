import { IconFlagList } from '@/constants'

export function getFlagIcons (key) {
  return IconFlagList.find(x => x.title === key).flag
}

export function getClassIcons (key) {
  return IconFlagList.find(x => x.title === key) ? IconFlagList.find(x => x.title === key).class : ''
}

export function getClassBackgroundIcons (key) {
  return IconFlagList.find(x => x.title === key) ? IconFlagList.find(x => x.title === key).background : ''
}
